import React, { useEffect } from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../wr-firebase";
import { withRouter } from "react-router-dom";

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    useEffect(() => {
      props.firebase.auth().onAuthStateChanged((authUser) => {
        if (!condition(authUser)) {
          props.history.push("/login");
        }
      });
    });

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (condition(authUser) ? <Component {...props} /> : null)}
      </AuthUserContext.Consumer>
    );
  };

  return withRouter(withFirebase(WithAuthorization));
};

export default withAuthorization;
