import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import SignOutButton from "dashboard/toolbar/signout";
import { makeStyles, withTheme } from "@mui/styles";
import {
  ArrowBack,
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { SwipeableDrawer, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { StateContext } from "../index";
import wrColors from "wrColors";
import DrawerLink from "./drawerLink";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  sidebar: {
    zIndex: theme.zIndex.drawer,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: theme.shadows[4],
    [theme.breakpoints.down("sm")]: {
      boxShadow: "None",
    },
  },
  sidebarPaper: {
    backgroundColor: wrColors.grey1,
    border: "None",
    color: "white",
    overflowX: "hidden",
  },
  sidebarOpen: {
    width: drawerWidth,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClose: {
    transition: theme.transitions.create(["width", "box-shadow"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 76,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  list: {
    height: "100vh",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  headerIcon: {
    fontSize: 24,
  },
  signout: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& div": {
      width: "70%",
    },
  },
}));

const DrawerContainer = ({ structure, claim, ...props }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  // const access = props.claim(structure);
  // console.log(access);

  useEffect(() => {
    const handlePermanent = () => {
      const windowWidth = window.innerWidth;
      const breakpointWidth = props.theme.breakpoints.values.md;
      const isSmallScreen = windowWidth < breakpointWidth;
      if (isSmallScreen && context.state.isPermanent) {
        context.actions.togglePermanent(false);
      } else if (!isSmallScreen && !context.state.isPermanent) {
        context.actions.togglePermanent(true);
      }
    };
    window.addEventListener("resize", handlePermanent);
    handlePermanent();
    return () => {
      window.removeEventListener("resize", handlePermanent);
    };
  });

  return (
    <SwipeableDrawer
      variant={context.state.isPermanent ? "permanent" : "temporary"}
      onClose={() => context.actions.toggleSidebar()}
      onOpen={() => context.actions.toggleSidebar()}
      className={classnames(classes.sidebar, {
        [classes.sidebarOpen]: context.state.isSidebarOpened,
        [classes.sidebarClose]: !context.state.isSidebarOpened,
      })}
      classes={{
        paper: classnames(classes.sidebarPaper, {
          [classes.sidebarOpen]: context.state.isSidebarOpened,
          [classes.sidebarClose]: !context.state.isSidebarOpened,
        }),
      }}
      open={context.state.isSidebarOpened}
    >
      <div className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => context.actions.toggleSidebar()}
          className={classes.headerMenuButton}
        >
          {context.state.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classes.headerIcon,
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classnames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
      </div>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => context.actions.toggleSidebar()}>
          <ArrowBack
            classes={{
              root: classnames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <div
        role="presentation"
        className={classes.list}
        onClick={() =>
          !context.state.isPermanent && context.actions.toggleSidebar()
        }
        onKeyDown={() =>
          !context.state.isPermanent && context.actions.toggleSidebar()
        }
      >
        {structure.map((link) =>
          claim[link.claim] ? (
            <DrawerLink
              key={link.id}
              location={props.location}
              isSidebarOpened={context.state.isSidebarOpened}
              isPermanent={context.state.isPermanent}
              {...link}
            />
          ) : null
        )}
        <Box className={classes.signout}>
          <SignOutButton display={{ xs: "block", md: "none" }} />
        </Box>
      </div>
    </SwipeableDrawer>
  );
};

export default withTheme(withRouter(DrawerContainer));
