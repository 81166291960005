import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import ErrorSvg from "./cancel.svg";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "33%",
    minWidth: 160,
    display: "block",
    margin: "0 auto",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const Error = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h2">
        Oeps...
      </Typography>
      <img src={ErrorSvg} alt="Error" className={classes.image} />
      <Typography align="center" variant="h6">
        Je hebt geen toegang tot de pagina die je wilt bezoeken.
      </Typography>
    </>
  );
};

export default Error;
