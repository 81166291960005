import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import SignOutButton from "dashboard/toolbar/signout";
import { StateContext } from "../index";
import logo from "logo.png";
import SelectProject from "components/inputs/selectProject";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
}));

const Toolbar = ({ structure, ...props }) => {
  const classes = useStyles();
  const context = useContext(StateContext);

  // const [page, setPage] = useState("");

  // useEffect(() => {
  //   const currentPage = structure.find(
  //     (x) => x.link === props.location.pathname
  //   );
  //   setPage(currentPage ? currentPage.label : "Dashboard");
  // }, [structure, props.location]);

  return (
    <>
      {!context.state.isPermanent ? (
        <IconButton
          color="inherit"
          onClick={() => context.actions.toggleSidebar()}
          className={classes.headerMenuButton}
        >
          <MenuIcon
            classes={{
              root: classnames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      ) : null}

      <SelectProject />
      {/* <Typography variant="h6" weight="medium">
          {page}
        </Typography> */}

      <img src={logo} alt="logo" width="40px" />

      <SignOutButton display={{ xs: "none", md: "block" }} />
    </>
  );
};

export default withRouter(Toolbar);
