import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import wrColors from "wrColors";
import {
  Person as AccountIcon,
  Dashboard as DashboardIcon,
  Settings as AdminIcon,
  Map as LocationsIcon,
  Cable as BeschikbaarheidIcon,
} from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/system";
import classnames from "classnames";
import { StateContext } from "./index";

// Pages
import Admin from "dashboard/pages/admin";
import Overview from "dashboard/pages/overview/overviewContainer";
import Account from "dashboard/pages/account";
import Locations from "dashboard/pages/locations/locationsContainer";
import Beschikbaarheid from "dashboard/pages/beschikbaarheid/beschikbaarheidContainer";

const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
    backgroundColor: wrColors.grey3,
  },
  content: {
    width: `calc(100vw - 240px)`,
    position: "relative",
    flexGrow: 1,
  },
  head: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    padding: theme.spacing(3),
    minHeight: "100vh",
  },
  mainShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  appcolor: {
    background: "white",
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "space-between",
  },
}));

export const structure = [
  {
    id: 0,
    component: Overview,
    label: "Dashboard",
    claim: "overview",
    access: true,
    link: "/overview",
    icon: <DashboardIcon />,
    position: "top",
  },
  {
    id: 1,
    component: Locations,
    label: "Locaties",
    claim: "overview",
    access: true,
    link: "/locaties",
    icon: <LocationsIcon />,
    position: "top",
  },
  {
    id: 2,
    component: Beschikbaarheid,
    label: "Bescikbaarheidscheck",
    claim: "overview",
    access: true,
    link: "/beschikbaarheid",
    icon: <BeschikbaarheidIcon />,
    position: "top",
  },
  {
    id: 3,
    component: Admin,
    label: "Admin",
    claim: "admin",
    access: false,
    link: "/admin",
    icon: <AdminIcon />,
    position: "top",
  },
  {
    id: 4,
    component: Account,
    label: "Account",
    claim: "account",
    access: true,
    link: "/account",
    icon: <AccountIcon />,
    position: "bottom",
  },
];

const Main = React.memo((props) => {
  const classes = useStyles();
  return (
    <main className={classnames(classes.main)}>
      <div className={classes.fakeToolbar} />
      {props.content}
    </main>
  );
});

const Layout = (props) => {
  const classes = useStyles();
  const context = useContext(StateContext);
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "100vw",
        overflowX: "hidden",
        backgroundColor: wrColors.grey3,
      }}
    >
      {props.drawer}
      <Box
        className={classnames(classes.content, {
          [classes.mainShift]: context.state.isSidebarOpened,
        })}
      >
        <AppBar
          position="absolute"
          classes={{ root: classes.appcolor }}
          className={classnames(classes.head)}
        >
          <Toolbar className={classes.toolbar}>{props.toolbar}</Toolbar>
        </AppBar>
        <Main content={props.children} />
      </Box>
    </Box>
  );
};

export default Layout;
