import React, { useContext } from "react";
import WrModal from "components/modal/wrModal";
import { Grid, TextField, Box, Button } from "@mui/material";
import firebase from "components/wr-firebase";
import { StateContext } from "dashboard";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";

//Modal to create new Project
export const ProjectModal = (props) => {
  const context = useContext(StateContext);
  const { handleSubmit, control } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  // TODO: Also add the possibility to initialize with a set of locations (from csv/copy paste)?

  // Submit new creation to Firestore
  const submit = (data) => {
    // Remove undefined fields from object
    Object.keys(data).forEach((key) =>
      data[key] === undefined ? delete data[key] : {}
    );
    // Create the new Project
    const ref = firebase.firestore().collection("projects").doc();
    // Fetch the id
    const id = ref.id;
    // Apply values to new project and set as active
    ref
      .set(data)
      .then(() => {
        context.actions.selectProject(
          // Workaround by refetching newly created project since no document is returned from set
          firebase.firestore().collection("projects").doc(id)
        );
        props.handleClose();
        enqueueSnackbar("Project toegevoegd", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Project toevoegen mislukt", {
          variant: "error",
        });
        console.error("Error adding document: ", error);
      });
  };

  return (
    <WrModal
      open={true}
      onClose={props.handleClose}
      title={"Nieuw Project"}
      actions={
        <>
          <Button onClick={props.handleClose}>Annuleer</Button>
          <Button
            onClick={handleSubmit(submit)}
            variant="contained"
            color="primary"
          >
            Opslaan
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: "Naam is een verplicht veld" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Naam"
                  variant="standard"
                  error={!!error}
                  helperText={error ? error.message : null}
                  {...field}
                />
              )}
            />
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <TextField label="Opmerking" variant="standard" {...field} />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </WrModal>
  );
};

const ProjectView = ({ id, setId, reload }) => {
  const open = id != null;
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else
    return <ProjectModal id={id} reload={reload} handleClose={handleClose} />;
};

export default ProjectView;
