import React, { useContext, useCallback } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ContainerItem } from "../overview/overviewContainer";
import { FirebaseLocationsWithUid } from "../locations/locationsContainer";
import { Grid, Tooltip } from "@mui/material";
import MaterialTable from "@material-table/core";
import { StateContext } from "dashboard";
import firebase from "components/wr-firebase";
import { tableTranslation } from "wrTheme";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { withAuthorization } from "components/session";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { FirebaseProjectWithUid } from "components/inputs/selectProject";
import BeschikbaarheidDetailPanel from "./beschikbaarheidDetailPanel";

interface Connection {
  name: string;
  state: "LOADING" | "ERROR" | "SUCCESS" | "QUEUED";
  available: string;
  speed?: string;
  remarks?: string;
}

// Create function to export data to excel using XLSX and FileSaver
const today = new Date().toISOString().split("T")[0];

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + ".xlsx");
};

// Create flat JSON dataformat for table export
const renderExport = (locations) => {
  return locations.map((location) => {
    const grexx_check = location.zipcheck.grexx.reduce(
      (
        acc: { [x: string]: any },
        check: { supplier: string; name: string; remarks: any; available: any }
      ) => {
        acc[check.supplier + " " + check.name] = check.remarks
          ? check.remarks
          : check.available;
        return acc;
      },
      {}
    );

    const ziggo_check = location.zipcheck.ziggo.reduce(
      (
        acc: { [x: string]: any },
        check: { supplier: string; name: string; remarks: any; available: any }
      ) => {
        acc[check.supplier + " " + check.name] = check.remarks
          ? check.remarks
          : check.available;
        return acc;
      },
      {}
    );

    return {
      name: location.name,
      street: location.street,
      zipcode: location.zipcode,
      housenr: location.housenr.toString(),
      housnrext: location.housenrext,
      remarks: location.remarks,
      ...grexx_check,
      ...ziggo_check,
    };
  });
};

// Render an icon based on the availability check result
const RenderIcon = ({ value }) => {
  const icons = {
    Green: <CheckCircleOutlineIcon color="success" />,
    true: <CheckCircleOutlineIcon color="success" />,
    false: <CheckCircleOutlineIcon color="success" />,
    Yellow: <WarningAmberIcon color="warning" />,
    Red: <HighlightOffIcon color="error" />,
    NOT_FOUND: <ErrorOutlineIcon color="error" />,
  };

  return icons[value] ? icons[value] : <></>;
};

// Render each supplier to a table friendly format including colors and icons
const renderSupplier = (data: any, provider: string, supplier: string[]) => {
  if (!data) return "";

  try {
    let connections: Connection[] = data[provider].filter((r: Connection) =>
      supplier.includes(r["supplier"])
    );

    if (connections.some((e) => e.state === "LOADING")) {
      return <CircularProgress />;
    }

    if (connections.length === 0) {
      connections = data[provider];
    }

    return (
      <>
        {connections.map((connection: Connection, i: number) => {
          return (
            <Tooltip
              placement="left"
              key={i}
              title={connection.remarks ? connection.remarks : connection.state}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gridGap: 5,
                }}
              >
                <RenderIcon
                  value={
                    connection.state === "SUCCESS"
                      ? connection.available
                      : connection.state
                  }
                />
                <Box
                  sx={{
                    fontWeight: "500",
                    color:
                      connection.available === "Yellow"
                        ? "warning.main"
                        : connection.available === "Red"
                        ? "error.main"
                        : "success.main",
                  }}
                >
                  {connection.name}
                </Box>
              </Box>
            </Tooltip>
          );
        })}
      </>
    );
  } catch (error) {
    console.error(error);
    return `Connectie ${supplier} voor provider ${provider} niet gevonden.`;
  }
};

const LocationsTable = ({
  project,
  page,
  setPage,
}: {
  project: FirebaseProjectWithUid;
  page: number;
  setPage: (value: number) => void;
}) => {
  const [locations, loading] = useCollectionData<FirebaseLocationsWithUid>(
    firebase
      .firestore()
      .collection("projects")
      .doc(project.id)
      .collection("locations"),
    {
      idField: "id",
    }
  );

  console.log(locations);
  return (
    <MaterialTable
      options={{
        columnsButton: true,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [25, 50, 75],
        padding: "dense",
        rowStyle: {
          overflowWrap: "break-word",
          verticalAlign: "top",
        },
        detailPanelType: "single",
        exportMenu: [
          {
            label: "Exporteer XLSX",
            exportFunc: () =>
              exportToCSV(
                renderExport(locations),
                "beschikbaarheidscheck-" + project.name + "-" + today
              ),
          },
        ],
      }}
      detailPanel={({ rowData }) => (
        <BeschikbaarheidDetailPanel rowData={rowData} />
      )}
      onRowClick={(event, rowData, togglePanel) =>
        togglePanel ? togglePanel() : null
      }
      //   onPageChange={(e, newPage) => {
      //     setPage(e);
      //   }}

      title={"Beschikbaarheidscheck"}
      isLoading={loading}
      localization={tableTranslation}
      data={locations !== undefined ? locations : []}
      columns={[
        {
          title: "Name",
          field: "name",
        },
        {
          title: "KPN",
          render: useCallback(
            ({ zipcheck }) => renderSupplier(zipcheck, "grexx", ["KPN"]),
            []
          ),
        },
        {
          title: "KPN WEAS",
          render: useCallback(
            ({ zipcheck }) => renderSupplier(zipcheck, "grexx", ["KPNWEAS"]),
            []
          ),
        },
        {
          title: "Eurofiber",
          render: useCallback(
            ({ zipcheck }) => renderSupplier(zipcheck, "grexx", ["Eurofiber"]),
            []
          ),
        },
        {
          title: "Tele2",
          render: useCallback(
            ({ zipcheck }) =>
              renderSupplier(zipcheck, "grexx", ["Tele2", "Tele2Fiber"]),
            []
          ),
        },
        {
          title: "ZIGGO",
          render: useCallback(
            ({ zipcheck }) => renderSupplier(zipcheck, "ziggo", ["ZIGGO"]),
            []
          ),
        },
      ]}
    />
  );
};

const BeschikbaarheidContainer = () => {
  const context = useContext(StateContext);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        {!context?.state.selectedProject ? (
          <ContainerItem>
            Selecteer alstublieft een project om locaties te kunnen zien
          </ContainerItem>
        ) : context?.state.selectedProject?.id ? (
          <LocationsTable
            project={context?.state.selectedProject}
            page={context?.state.locationTablePage}
            setPage={context?.actions.setLocationTablePage}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

const condition = (authUser: any) => !!authUser;

export default withAuthorization(condition)(BeschikbaarheidContainer);
