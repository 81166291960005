import React, { useEffect, useState } from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../wr-firebase";

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
      props.firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          console.log(
            "Logged in as: " +
              (authUser._delegate ? authUser._delegate.email : authUser.email)
          );
          setAuthUser(authUser);
        } else {
          console.log("Logged out");
          setAuthUser(false);

          console.log("Redirect results");
          props.firebase
            .auth()
            .getRedirectResult()
            .then((result) => console.log(result))
            .catch((error) => console.log(error));
        }
      });
    });

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
