import React from "react";
import WrModal from "components/modal/wrModal";

export const BasicModal = (props) => {
  return (
    <WrModal open={true} onClose={props.handleClose} title={"default"}>
      Works
    </WrModal>
  );
};

const BasicView = ({ id, setId, reload }) => {
  const open = id != null;
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else return <BasicModal id={id} reload={reload} handleClose={handleClose} />;
};

export default BasicView;
