import React from "react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Users from "./users";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  users: {
    width: "auto",
    padding: theme.spacing(1),
  },
}));

const Admin = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.users}>
      <Users />
    </Paper>
  );
};

export default Admin;
