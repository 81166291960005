import React, { useState, useContext, useEffect } from "react";
import { produce } from "immer";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { FirebaseContext } from "components/wr-firebase";
import { ModalProvider } from "components/modal/context";
import DrawerContainer from "./drawer";
import Toolbar from "dashboard/toolbar";
import Layout, { structure } from "./Layout";
import { Modal } from "components/modal/context";

import Error from "./pages/error";
import { FirebaseProjectWithUid } from "components/inputs/selectProject";

interface IState {
  isSidebarOpened: boolean;
  isPermanent: boolean;
  selectedProject: FirebaseProjectWithUid | undefined;
  locationTablePage: number;
}

interface IStateContext {
  state: IState;
  actions: {
    toggleSidebar: () => void;
    togglePermanent: (value: boolean) => void;
    selectProject: (value: FirebaseProjectWithUid) => void;
    setLocationTablePage: (value: number) => void;
  };
}

interface IRouteProps {
  component: any;
  access: string;
  label: string;
  [x: string]: any;
}

const initialState = {
  isSidebarOpened: false,
  isPermanent: true,
  selectedProject: undefined,
  locationTablePage: 0,
};

export const StateContext = React.createContext<IStateContext | undefined>(
  undefined
);

const Dashboard = () => {
  const [state, setState] = useState<IState>(initialState);

  const toggleSidebar = () => {
    const newState = produce(state, (draftstate) => {
      draftstate.isSidebarOpened = !state.isSidebarOpened;
    });
    setState(newState);
  };

  const togglePermanent = (value: boolean) => {
    const newState = produce(state, (draftstate) => {
      draftstate.isPermanent = value;
    });
    setState(newState);
  };

  const selectProject = (value: FirebaseProjectWithUid) => {
    const newState = produce<IState>(state, (draftstate) => {
      draftstate.selectedProject = value;
    });
    setState(newState);
  };

  const setLocationTablePage = (value: number) => {
    const newState = produce<IState>(state, (draftstate) => {
      draftstate.locationTablePage = value;
    });
    setState(newState);
  };

  const context = {
    state: state,
    actions: {
      toggleSidebar: toggleSidebar,
      togglePermanent: togglePermanent,
      selectProject: selectProject,
      setLocationTablePage: setLocationTablePage,
    },
  };

  const FBContext: any = useContext(FirebaseContext);

  const [claim, setClaim] = useState<any>({});

  useEffect(() => {
    FBContext.doClaimCheck(structure, (data: any) => {
      setClaim(data);
    });
  }, [FBContext]);

  const RenderRoute = ({
    component: Component,
    access,
    label,
    ...rest
  }: IRouteProps) => (
    <Route
      {...rest}
      render={(props) => {
        if (claim[access] || access === undefined) {
          return <Component {...props} />;
        } else {
          return <Error />;
        }
      }}
    />
  );

  const toolbarProps = {
    structure: structure,
  };

  const drawerProps = {
    structure: structure,
    claim: claim,
  };

  return (
    <StateContext.Provider value={context}>
      <ModalProvider>
        <BrowserRouter>
          <React.Fragment>
            <Layout
              drawer={<DrawerContainer {...drawerProps} />}
              toolbar={<Toolbar {...toolbarProps} />}
            >
              <Switch>
                {structure.map((page) => (
                  <RenderRoute
                    exact
                    key={page.id}
                    access={page.claim}
                    path={page.link}
                    label={page.label}
                    component={page.component}
                  />
                ))}
                <Redirect to="/overview" />
              </Switch>
            </Layout>
            <Modal />
          </React.Fragment>
        </BrowserRouter>
      </ModalProvider>
    </StateContext.Provider>
  );
};

export default Dashboard;
