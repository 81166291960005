import React, { FunctionComponent } from "react";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface FloatingButtonProps {
  tooltip: String;
  onClick: () => void;
}

const FloatingButton: FunctionComponent<FloatingButtonProps> = ({
  tooltip,
  onClick,
}) => {
  return (
    <Tooltip title={tooltip}>
      <Fab
        color="primary"
        aria-label="add"
        sx={(theme) => ({
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        })}
        onClick={onClick}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};

export default FloatingButton;
