import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";

import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { ModalContext } from "./context";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    margin: 0,
    padding: theme.spacing(1, 2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  closeWithIcon: {
    top: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, icon, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
      {icon}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={clsx(classes.closeButton, { [classes.closeButton]: icon })}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const WrModal = ({ open, children, actions, onEnter, title, icon }) => {
  const { dispatch } = React.useContext(ModalContext);

  if (!open) return <></>;
  else
    return (
      <>
        <Dialog
          open={open}
          onClose={() => dispatch({ type: "hide" })}
          maxWidth={"md"}
          onKeyPress={(e) => {
            if (onEnter && e.key === "Enter") onEnter();
          }}
        >
          <DialogTitle
            id="customized-dialog-title"
            icon={icon}
            onClose={() => dispatch({ type: "hide" })}
          >
            {title}
          </DialogTitle>
          <DialogContent dividers>{children}</DialogContent>
          {actions ? <DialogActions>{actions}</DialogActions> : <></>}
        </Dialog>
      </>
    );
};

export default WrModal;
