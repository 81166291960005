import React, { FunctionComponent, useState } from "react";

import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import logo from "logo.png";
import microsoft from "./ms-symbollockup_signin_dark.svg";

type LoginLayoutProps = {
  onMicrosoft: any;
  onSubmit: any;
};
const LoginLayout: FunctionComponent<LoginLayoutProps> = ({
  onMicrosoft,
  onSubmit,
  children,
}) => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            "url(https://source.unsplash.com/featured?technology/1600x900)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            alignSelf: "flex-start",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img src={logo} alt="logo" width="70px" />

          <Typography
            component="h1"
            variant="h5"
            sx={{
              mt: 2,
            }}
          >
            Inloggen WR Control
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            width: "70%",
          }}
        >
          <Box>
            <Box
              onClick={onMicrosoft}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <img src={microsoft} alt="microsoft" width="200px" />
            </Box>
            <Typography
              onClick={() => setShowForm(!showForm)}
              align="center"
              variant="subtitle2"
              sx={{
                mt: 2,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              Login using form
            </Typography>
          </Box>
          {showForm ? (
            <Box
              component="form"
              sx={{
                width: "100%",
                mt: 1,
                "& .MuiButton-label": {
                  color: "white",
                },
              }}
              noValidate
              onSubmit={onSubmit}
            >
              {children}
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
