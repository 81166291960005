import { Grid, Paper } from "@mui/material";
import React, { useContext } from "react";

import { FirebaseProjectWithUid } from "components/inputs/selectProject";
import FloatingButton from "components/inputs/floatingButton";
import { PinDrop as LocationsIcon } from "@mui/icons-material";
import MaterialTable from "@material-table/core";
import { ModalContext } from "components/modal/context";
import { StateContext } from "dashboard";
import colors from "wrColors";
import firebase from "components/wr-firebase";
import { styled } from "@mui/material/styles";
import { tableTranslation } from "wrTheme";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { withAuthorization } from "components/session";

export const ContainerItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OverviewContainer = () => {
  const context = useContext(StateContext);
  const { dispatch } = useContext(ModalContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // Hook to fetch als projects
  const [projects, loading] = useCollectionData<FirebaseProjectWithUid>(
    firebase.firestore().collection("projects"),
    {
      idField: "id",
    }
  );

  const makeProjectActive = (selectedRow: any) => {
    if (selectedRow) {
      const project = projects?.find((x) => x.id === selectedRow.id);
      if (
        project &&
        context?.actions.selectProject &&
        project !== context?.state.selectedProject
      ) {
        context?.actions.selectProject(project);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <MaterialTable
            options={{
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20],
              rowStyle: (rowData) => ({
                backgroundColor:
                  context?.state.selectedProject?.id === rowData.tableData.id
                    ? colors.grey3
                    : "#FFF",
              }),
            }}
            onRowClick={(evt, selectedRow) => makeProjectActive(selectedRow)}
            title={"Projecten"}
            isLoading={loading}
            localization={tableTranslation}
            editable={{
              onRowUpdate: (newData, oldData) => {
                return firebase
                  .firestore()
                  .collection("projects")
                  .doc(oldData?.id)
                  .update(newData)
                  .then(() => {
                    enqueueSnackbar("Project aangepast", {
                      variant: "success",
                    });
                  })
                  .catch((error) => {
                    enqueueSnackbar("Project aanpassen mislukt", {
                      variant: "error",
                    });
                    console.error("Error changing document: ", error);
                  });
              },
            }}
            data={projects !== undefined ? projects : []}
            columns={[
              {
                title: "Naam",
                field: "name",
                editable: "onUpdate",
              },
              {
                title: "Id",
                field: "id",
                editable: "never",
              },
            ]}
            actions={[
              {
                icon: LocationsIcon,
                tooltip: "Bekijk locaties",
                onClick: (event, rowData) => {
                  makeProjectActive(rowData);
                  history.push("/locaties");
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <FloatingButton
        tooltip={"Nieuw Project"}
        onClick={() =>
          dispatch({
            type: "show",
            modalType: "project",
            modalProps: {
              handleClose: () => dispatch({ type: "hide" }),
            },
          })
        }
      />
    </>
  );
};

const condition = (authUser: any) => !!authUser;

export default withAuthorization(condition)(OverviewContainer);
