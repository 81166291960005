import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as SystemThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import CssBaseline from "@mui/material/CssBaseline";
import wrTheme from "wrTheme";
import reportWebVitals from "./reportWebVitals";

import { withAuthentication, AuthUserContext } from "components/session";

import Login from "login";

import "./index.css";

import Firebase, { FirebaseContext } from "components/wr-firebase";
import Dashboard from "./dashboard";

const SwitchViewBase = ({
  authUser,
  location,
  history,
  redirect,
  setRedirect,
}) => {
  if (authUser === false) {
    if (location.pathname !== "/login") {
      setRedirect(location.pathname);
      if (redirect === false) history.push("/login");
    } else if (redirect === false) setRedirect("/");
    return <Login redirect={redirect} />;
  } else if (typeof authUser === "object" && authUser !== null)
    return <Dashboard />;
  else return <></>;
};
const SwitchView = withRouter(SwitchViewBase);

const App = () => {
  const authUser = useContext(AuthUserContext);
  const [redirect, setRedirect] = useState(false);

  return (
    <ThemeProvider theme={wrTheme}>
      <SystemThemeProvider theme={wrTheme}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <ConfirmProvider
            defaultOptions={{
              title: "Weet je het zeker?",
              cancellationText: "Annuleer",
            }}
          >
            <BrowserRouter>
              <SwitchView
                authUser={authUser}
                redirect={redirect}
                setRedirect={setRedirect}
              />
            </BrowserRouter>
          </ConfirmProvider>
        </SnackbarProvider>
      </SystemThemeProvider>
    </ThemeProvider>
  );
};

const AuthApp = withAuthentication(App);

ReactDOM.render(
  <FirebaseContext.Provider value={Firebase}>
    <CssBaseline />
    <AuthApp />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
