import React, { useContext } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import firebase from "components/wr-firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { StateContext } from "dashboard";
import { ModalContext } from "components/modal/context";

export type FirebaseProjectWithUid = {
  name: string;
  remarks?: string;
  id: string;
};

export default function SelectProject() {
  const context = useContext(StateContext);
  const { dispatch } = useContext(ModalContext);

  const handleChange = (event: SelectChangeEvent) => {
    // Check if we want to create new project
    if (event.target.value === "new") {
      dispatch({
        type: "show",
        modalType: "project",
        modalProps: {
          handleClose: () => dispatch({ type: "hide" }),
        },
      });
    } else {
      // Else we set the selected project to global state
      const project = projects?.find((x) => x.id === event.target.value);
      if (project && context?.actions.selectProject) {
        context?.actions.selectProject(project);
      }
    }
  };

  // Hook to fetch als projects
  const [projects, loading] = useCollectionData<FirebaseProjectWithUid>(
    firebase.firestore().collection("projects"),
    {
      idField: "id",
    }
  );

  if (loading) {
    return <></>;
  }

  return (
    <Box sx={{ minWidth: 150, my: 1 }}>
      <FormControl fullWidth>
        <InputLabel id="projects-select">Project</InputLabel>
        <Select
          labelId="projects-select-label"
          id="projects-select"
          value={
            context?.state.selectedProject?.id
              ? context?.state.selectedProject?.id
              : ""
          }
          label="Project"
          onChange={handleChange}
        >
          {projects?.map((project, index) => (
            <MenuItem key={index} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
          <MenuItem value={"new"}>Nieuw Project...</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
