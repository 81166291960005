import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { withFirebase } from "components/wr-firebase";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    width: "100%",
  },
}));

const SignOutButton = ({ firebase, display }) => {
  const classes = useStyles();
  return (
    <Box display={display}>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        onClick={firebase.doSignOut}
      >
        Uitloggen
      </Button>
    </Box>
  );
};

export default withFirebase(SignOutButton);
