import React, { useCallback, useContext } from "react";

import { ContainerItem } from "../overview/overviewContainer";
import FloatingButton from "components/inputs/floatingButton";
import { Grid, TextField } from "@mui/material";
import MaterialTable from "@material-table/core";
import { ModalContext } from "components/modal/context";
import { StateContext } from "dashboard";
import firebase from "components/wr-firebase";
import { tableTranslation } from "wrTheme";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useSnackbar } from "notistack";
import { withAuthorization } from "components/session";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export type FirebaseLocationsWithUid = {
  housenr?: string;
  housenrext?: string;
  name?: string;
  remarks?: string;
  street?: string;
  zipcode?: string;
  zipcheck?: any;
  invalid?: boolean;
  id: string;
};

const LocationsTable = ({ id }: { id: string }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [locations, loading] = useCollectionData<FirebaseLocationsWithUid>(
    firebase.firestore().collection("projects").doc(id).collection("locations"),
    {
      idField: "id",
    }
  );

  const renderHousnr = useCallback(
    (rowData) =>
      rowData?.invalid ? (
        <WarningAmberIcon color="warning" />
      ) : (
        rowData.housenr + (rowData.housenrext ? " " + rowData.housenrext : "")
      ),
    []
  );

  const renderStreet = useCallback(
    (rowData) =>
      rowData?.invalid ? <WarningAmberIcon color="warning" /> : rowData.street,
    []
  );

  return (
    <MaterialTable
      options={{
        columnsButton: true,
        pageSize: 50,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [25, 50, 75],
      }}
      title={"Locaties"}
      isLoading={loading}
      localization={tableTranslation}
      editable={{
        onRowUpdate: (newData, oldData) => {
          if (oldData?.invalid) {
            newData.invalid = false;
          }

          return firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .collection("locations")
            .doc(oldData?.id)
            .update(newData)
            .then(() => {
              enqueueSnackbar("Locatie aangepast", {
                variant: "success",
              });
            })
            .catch((error) => {
              enqueueSnackbar("Locatie aanpassen mislukt", {
                variant: "error",
              });
              console.error("Error changing document: ", error);
            });
        },
        onRowDelete: (oldData) => {
          return firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .collection("locations")
            .doc(oldData?.id)
            .delete()
            .then(() => {
              enqueueSnackbar("Locatie verwijdert", {
                variant: "success",
              });
            })
            .catch((error) => {
              enqueueSnackbar("Locatie verwijderen mislukt", {
                variant: "error",
              });
              console.error("Error removing document: ", error);
            });
        },
      }}
      data={locations !== undefined ? locations : []}
      columns={[
        {
          title: "Name",
          field: "name",
        },
        {
          title: "Straat",
          field: "street",
          render: renderStreet,
        },
        {
          title: "Huisnummer",
          field: "housenr",
          render: renderHousnr,
          editComponent: (props) => {
            console.log(props);
            return (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField
                    variant="standard"
                    id="housenr"
                    value={props.value}
                    placeholder="Huisnummer"
                    onChange={(e) => props.onChange(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <TextField
                    variant="standard"
                    id="housenrext"
                    placeholder="Toevoeging"
                    onChange={(e) =>
                      props.onRowDataChange({
                        ...props.rowData,
                        housenrext: e.target.value,
                      })
                    }
                    value={props.rowData.housenrext}
                  />
                </Grid>
              </Grid>
            );
          },
        },
        {
          title: "Postcode",
          field: "zipcode",
        },
        {
          title: "Opmerking",
          field: "remarks",
        },
      ]}
    />
  );
};

const LocationsContainer = () => {
  const context = useContext(StateContext);

  const { dispatch } = useContext(ModalContext);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {!context?.state.selectedProject ? (
            <ContainerItem>
              Selecteer alstublieft een project om locaties te kunnen zien
            </ContainerItem>
          ) : context?.state.selectedProject?.id ? (
            <LocationsTable id={context?.state.selectedProject?.id} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {!context?.state.selectedProject ? (
        <></>
      ) : (
        <FloatingButton
          tooltip={"Nieuwe Locatie"}
          onClick={() =>
            dispatch({
              type: "show",
              modalType: "location",
              modalProps: {
                handleClose: () => dispatch({ type: "hide" }),
              },
            })
          }
        />
      )}
    </>
  );
};

const condition = (authUser: any) => !!authUser;

export default withAuthorization(condition)(LocationsContainer);
