import React, { useContext, useState } from "react";
import WrModal from "components/modal/wrModal";
import { Grid, TextField, Button, Tab, AppBar } from "@mui/material";
import firebase from "components/wr-firebase";
import { StateContext } from "dashboard";
import { useForm, Controller } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import wrColors from "wrColors";
import LocationAddMultipleViews from "./locationAddMultipleView";
import { useSnackbar } from "notistack";

//Modal to create new Project
export const LocationModal = (props) => {
  const context = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control } = useForm();

  const [tabValue, setTabValue] = useState("1");
  const [multiLocations, setMultiLocations] = useState();

  // Event handler for tab changes
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Submit new creation to Firestore
  const submit = (data) => {
    // Remove undefined fields from object
    Object.keys(data).forEach((key) =>
      data[key] === undefined ? delete data[key] : {}
    );
    // Create the new Location and close modal
    firebase
      .firestore()
      .collection("projects")
      .doc(context.state.selectedProject.id)
      .collection("locations")
      .doc()
      .set(data)
      .then(() => {
        props.handleClose();
        enqueueSnackbar("Locatie toegevoegd", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Locatie toevoegen mislukt", {
          variant: "error",
        });
        console.error("Error adding document: ", error);
      });
  };

  // Submit new creation of multiple locations to Firestore
  const submitMultiple = () => {
    // Remove undefined fields from object

    // Perform some transformations and checks
    let saveLocations = multiLocations.map((l) => {
      let address = l["address"].trim().split(" ");
      let housenrIndex = address.findIndex((x) => /\d/.test(x));

      if (housenrIndex > 0) {
        let housenrext = address[housenrIndex]
          .slice(address[housenrIndex].search(/\d/))
          .match(/[a-zA-Z]+[\d]*/g);

        return {
          name: l["name"],
          zipcode: l["zipcode"].replace(/\s/g, ""), //erg belangrijk met een space in zipcode tript ie
          street: address.slice(0, housenrIndex).join(" "),
          housenr: Number(address[housenrIndex].match(/\d+/)[0]),
          housenrext: housenrext == null ? "" : housenrext[0],
          remarks: l["remarks"]
            ? l["remarks"].replace(/^[\r\n]+|\.|[\r\n]+$/g, "")
            : "",
        };
      } else {
        enqueueSnackbar(
          l["name"] + " voldoet niet aan adres eisen, pas de gegevens aan.",
          {
            variant: "warning",
          }
        );
        return {
          name: l["name"],
          zipcode: l["zipcode"].replace(/\s/g, ""), //erg belangrijk met een space in zipcode tript ie
          street: address,
          housenr: "",
          housenrext: "",
          remarks: l["remarks"]
            ? l["remarks"].replace(/^[\r\n]+|\.|[\r\n]+$/g, "")
            : "",
          invalid: true,
        };
      }
    });

    // Send locations to firebase
    const db = firebase
      .firestore()
      .collection("projects")
      .doc(context.state.selectedProject.id)
      .collection("locations");

    // Load a batch job to iteratively add locations
    let batch = firebase.firestore().batch();

    saveLocations.forEach((location) => {
      batch.set(db.doc(), location);
    });

    // Execute the batch
    batch
      .commit()
      .then(() => {
        props.handleClose();
        enqueueSnackbar(saveLocations.length + " locaties toegevoegd", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Locaties toevoegen mislukt", {
          variant: "error",
        });
        console.error("Error adding document: ", error);
      });
  };

  return (
    <WrModal
      open={true}
      onClose={props.handleClose}
      title={"Locaties toevoegen"}
      actions={
        <>
          <Button onClick={props.handleClose}>Annuleer</Button>
          <Button
            onClick={tabValue === "1" ? submitMultiple : handleSubmit(submit)}
            variant="contained"
            color="primary"
          >
            Opslaan
          </Button>
        </>
      }
    >
      <TabContext value={tabValue}>
        <AppBar
          position="static"
          sx={{
            bgcolor: wrColors.grey3,
            width: "calc(100% + 32px)",
            m: "-16px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Meerdere locaties toevoegen" value="1" />
            <Tab label="Enkele locatie toevoegen" value="2" />
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <LocationAddMultipleViews
            data={multiLocations}
            setData={setMultiLocations}
          />
        </TabPanel>
        <TabPanel value="2">
          <Grid
            container
            spacing={2}
            sx={{
              width: "50ch",
            }}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: "Naam is een verplicht veld" }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    label="Naam"
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="street"
              control={control}
              rules={{ required: "Straat is een verplicht veld" }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    label="Straat"
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="housenr"
              control={control}
              rules={{
                required: "Huisnummer is een verplicht veld",
                pattern: {
                  value: /^\d+$/,
                  message: "Geen geldig nummer",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12} sm={6} lg={6}>
                  <TextField
                    label="Huisnummer"
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="housenrext"
              control={control}
              render={({ field }) => (
                <Grid item xs={12} sm={6} lg={6}>
                  <TextField
                    label="Toevoeging Huisnummer"
                    variant="standard"
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Grid item xs={12} sm={8} lg={8}>
                  <TextField
                    label="Plaats"
                    variant="standard"
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="zipcode"
              control={control}
              rules={{
                pattern: {
                  value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                  message: "Geen geldige postcode",
                },
                required: "Postcode is een verplicht veld",
              }}
              render={({ field, fieldState: { error } }) => (
                <Grid item xs={12} sm={4} lg={4}>
                  <TextField
                    label="Postcode"
                    variant="standard"
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <Grid item xs={12} sm={12} lg={12}>
                  <TextField
                    label="Opmerking"
                    variant="standard"
                    fullWidth
                    {...field}
                  />
                </Grid>
              )}
            />
          </Grid>
        </TabPanel>
      </TabContext>
    </WrModal>
  );
};

const LocationView = ({ id, setId, reload }) => {
  const open = id != null;
  const handleClose = () => {
    if (open) setId(null);
  };
  if (!open) return <></>;
  else
    return <LocationModal id={id} reload={reload} handleClose={handleClose} />;
};

export default LocationView;
