import React from "react";
import { makeStyles } from "@mui/styles";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Button from "@mui/material/Button";
// import EditIcon from "@mui/icons-material/Edit";
// import Checkbox from "@mui/material/Checkbox";
// import CloseIcon from "@mui/icons-material/Close";
import wrColors from "wrColors";

// import useApi, { api } from "components/api";
// import EditUser from "./edituser";
// import DeleteUser from "./deleteuser";

// import customClaims from "customClaims";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    width: "100%",
    padding: theme.spacing(3),
    overflowX: "auto",
  },
  buttonContainer: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  editbutton: {
    "& path": {
      fill: "white",
    },
  },
  button: {
    color: "white",
  },
  closebutton: {
    "& path": { fill: "white" },
    backgroundColor: wrColors.warn,
    "&:hover": {
      backgroundColor: wrColors.warn,
    },
  },
}));

const Users = () => {
  // const [edit, setEdit] = useState(null);
  // const [deleteuser, setDeleteUser] = useState(null);

  const classes = useStyles();
  // const [{ data, loading, error }, refetch] = useApi("/api/users/list");

  // if (loading) return <span>"Loading..."</span>;
  // else if (error) {
  //   console.error(error);
  //   return <span>"Error"</span>;
  // }

  // //parse customclaims if undefined
  // const parsedData = {
  //   ...data,
  //   users: data.users.map((user) => {
  //     if (user.customClaims === undefined) user.customClaims = {};
  //     customClaims.forEach((claim) => {
  //       if (user.customClaims[claim.field] === undefined)
  //         user.customClaims[claim.field] = false;
  //     });
  //     return user;
  //   }),
  // };

  // const changeClaim = (uid, claims) => {
  //   console.log("Updating custom claims");
  //   console.log("uid: " + uid + "\nclaims: " + JSON.stringify(claims));

  //   api({ method: "post", url: "/api/users/setclaims", data: { uid, claims } })
  //     .then((res) => {
  //       refetch();
  //     })
  //     .catch((err) => {
  //       console.error("An api error occured");
  //       console.error(JSON.stringify(err));
  //       console.error(err.response.data.message);
  //     });
  // };

  return (
    <div className={classes.root}>
      {/* <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Gebruiker</TableCell>
            <TableCell>Email</TableCell>
            {customClaims.map((claim) => (
              <TableCell key={claim.label}>{claim.label}</TableCell>
            ))}
            <TableCell>Bewerken</TableCell>
            <TableCell>Verwijderen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedData.users.map((user) => (
            <TableRow key={user.uid}>
              <TableCell component="th" scope="row">
                {user.displayName}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              {customClaims.map((claim) => (
                <TableCell key={claim.field}>
                  <Checkbox
                    color="primary"
                    checked={user.customClaims[claim.field]}
                    onChange={(e) =>
                      changeClaim(user.uid, {
                        ...user.customClaims,
                        [claim.field]: e.target.checked,
                      })
                    }
                  />
                </TableCell>
              ))}
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setEdit(user)}
                  className={classes.editbutton}
                >
                  <EditIcon />
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => setDeleteUser(user)}
                  className={classes.closebutton}
                >
                  <CloseIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() =>
            setEdit({
              displayName: "",
              email: "",
              password: "",
            })
          }
        >
          Toevoegen
        </Button>
      </div>
      <EditUser user={edit} setUser={setEdit} refetch={refetch} />
      <DeleteUser user={deleteuser} setUser={setDeleteUser} refetch={refetch} /> */}
    </div>
  );
};

export default Users;
