import app from "firebase";
import "firebase/firebase-firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAaPK6oUyVLZ-7vOOc8BSS7aHIMc_257A8",
  authDomain: "wr-control.firebaseapp.com",
  projectId: "wr-control",
  storageBucket: "wr-control.appspot.com",
  messagingSenderId: "895667315319",
  appId: "1:895667315319:web:e46e92b7992452540e1c72",
};

app.initializeApp(firebaseConfig);

const Firebase = {
  auth: app.auth,
  firestore: app.firestore,

  doCreateUserWithEmailAndPassword: (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password),

  doSignInWithEmailAndPassword: (email, password) =>
    app.auth().signInWithEmailAndPassword(email, password),

  doSignInWithMicrosoft: () => {
    const provider = new app.auth.OAuthProvider("microsoft.com");
    app.auth().signInWithRedirect(provider);
  },

  doSignOut: () => app.auth().signOut(),

  doPasswordReset: (email) => app.auth().sendPasswordResetEmail(email),

  doPasswordUpdate: (password) =>
    app.auth().currentUser.updatePassword(password),

  store: (generateDoc) => {
    app
      .storage()
      .ref()
      .child("tag-example.docx")
      .getDownloadURL()
      .then((url) => {
        generateDoc(url);
      });
  },

  doClaimCheck: (claims, callback) => {
    app
      .auth()
      .currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        const claimsAcces = {};
        claims.forEach((claim) => {
          const email = idTokenResult.claims.email;
          if (
            email === "tim@ellemeet.nl" ||
            email === "olafwallaart@gmail.com"
          ) {
            claimsAcces[claim.claim] = true;
          } else if (idTokenResult.claims[claim.claim] !== undefined)
            claimsAcces[claim.claim] = idTokenResult.claims[claim.claim];
          else claimsAcces[claim.claim] = claim.access;
        });

        callback(claimsAcces);
        // Confirm the user is an Admin.
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export default Firebase;
