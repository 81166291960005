import { createTheme } from "@mui/material/styles";
import colors from "./wrColors";
import { Theme } from "@mui/material/styles";
import { Localization } from "@material-table/core";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const wrTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
});

export default wrTheme;

export const tableTranslation: Localization = {
  body: {
    editRow: {
      deleteText: "Weet je zeker dat je dit wilt verwijderen?",
      saveTooltip: "Opslaan",
      cancelTooltip: "Annuleren",
    },
    editTooltip: "Bewerken",
    deleteTooltip: "Verwijderen",
  },
  header: {
    actions: "Acties",
  },
  toolbar: {
    searchPlaceholder: "Zoeken",
    searchTooltip: "Zoeken",
  },
  pagination: {
    labelRowsSelect: "rijen",
    labelRowsPerPage: "Rijen per pagina",
    labelDisplayedRows: "{from}-{to} van {count}",
  },
};
