import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MaterialTable from "@material-table/core";
import CircleIcon from "@mui/icons-material/Circle";

import wrColors from "wrColors";
import { tableTranslation } from "wrTheme";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import { FirebaseLocationsWithUid } from "../locations/locationsContainer";

const BeschikbaarheidDetailPanel = ({
  rowData,
}: {
  rowData: Data<FirebaseLocationsWithUid, "", "">;
}) => {
  const [tabValue, setTabValue] = React.useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  if (!rowData?.zipcheck) {
    return (
      <Box
        sx={{
          backgroundColor: wrColors.grey3,
          p: 1,
          textAlign: "center",
        }}
      >
        geen zipcheck data beschikbaar
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: wrColors.grey3,
      }}
    >
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            centered
          >
            {Object.keys(rowData?.zipcheck)
              .sort()
              .map((provider, i) => (
                <Tab label={provider} value={i.toString()} key={i} />
              ))}
          </TabList>
        </Box>
        {Object.keys(rowData?.zipcheck)
          .sort()
          .map((provider, i) => (
            <TabPanel value={i.toString()} key={i} sx={{ padding: 0 }}>
              {/* {JSON.stringify(rowData?.zipcheck[provider])} */}
              <MaterialTable
                components={{
                  Container: (props) => (
                    <Paper
                      elevation={0}
                      sx={{ backgroundColor: "transparent" }}
                      {...props}
                    />
                  ),
                  Toolbar: (props) => <></>,
                }}
                options={{
                  paging: false,
                  search: false,
                  headerStyle: { backgroundColor: "transparent" },
                }}
                title={`Beschikbaarheidscheck ${rowData.name} ${
                  rowData.street
                } ${rowData.housenr} ${rowData.remarks ? rowData.remarks : ""}`}
                data={rowData?.zipcheck[provider].map((item, id) => ({
                  id,
                  ...item,
                }))}
                localization={tableTranslation}
                columns={[
                  {
                    title: "Name",
                    field: "name",
                  },
                  {
                    title: "Status",
                    field: "state",
                  },
                  {
                    title: "Supplier",
                    field: "supplier",
                  },
                  {
                    title: "Beschikbaar",
                    field: "available",
                    render: ({ available }) => (
                      <CircleIcon
                        color={
                          available === "Red"
                            ? "error"
                            : available === "Green"
                            ? "success"
                            : "warning"
                        }
                      />
                    ),
                  },
                  {
                    title: "Opmerkingen",
                    field: "remarks",
                    cellStyle: {
                      minWidth: 500,
                    },
                  },
                ]}
              />
            </TabPanel>
          ))}
      </TabContext>
    </Box>
  );
};

export default BeschikbaarheidDetailPanel;
