import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import MaterialTable from "@material-table/core";
import { Box } from "@mui/system";
import { tableTranslation } from "wrTheme";

//Modal tab to add multiple locations
const LocationAddMultipleViews = ({ data, setData }) => {
  const [count, setCount] = useState();

  const handleClipboardEvent = (e) => {
    e.preventDefault(); // prevent default pasting behaviour (pasting value in input)

    // Process data so we can show it in a material table
    let result = e.clipboardData
      .getData("text/plain")
      .split("\n")
      .map((l) => l.split("\t"))
      .filter((l) => l[0] !== "")
      .map((loc, i) => {
        return {
          id: data ? data.length + i : i,
          name: loc[0],
          address: loc[1],
          zipcode: loc[2],
          remarks: loc[3]
            ? loc[3].charAt(0) === '"'
              ? loc[3].slice(1, -2)
              : loc[3]
            : "",
        };
      })
      .slice(0, 500); // at most 500 items since this is the max a firebase batch can do at a single time
    // check if already data in table, if so append else create data object for table
    if (data) {
      setData((data) => [...data, ...result]);
    } else {
      setData(result);
    }

    // log the lenght of the added data object
    setCount(result.length);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        minWidth: { lg: 800 },
      }}
    >
      <Grid item xs={12} sm={12} lg={12}>
        <TextField
          variant="standard"
          label="Plak hier template data (max. 500 rijen)"
          fullWidth
          onPaste={handleClipboardEvent}
        />
      </Grid>

      {/* Only show reset and row additions if we did a paste procedure  */}
      {count && (
        <>
          <Grid item xs={12} sm={6} lg={6}>
            <Typography variant="subtitle1" component="div">
              {count} rijen toegevoegd aan onderstaande tabel. Controleer de
              gegevens a.u.b.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Button
              onClick={() => {
                setData(undefined);
                setCount(undefined);
              }}
            >
              Klik hier om de tabel te legen.
            </Button>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={12} lg={12}>
        <MaterialTable
          components={{
            Container: (props) => <Box>{props.children}</Box>,
            Toolbar: () => null,
          }}
          options={{
            search: false,
            showTitle: false,
            paging: false,
          }}
          localization={tableTranslation}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);

                  resolve();
                }, 100);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);

                  resolve();
                }, 100);
              }),
          }}
          data={data}
          columns={[
            {
              title: "Naam",
              field: "name",
            },
            {
              title: "Adres",
              field: "address",
            },
            {
              title: "Postcode",
              field: "zipcode",
            },
            {
              title: "Opmerkingen",
              field: "remarks",
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default LocationAddMultipleViews;
