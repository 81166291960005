const wrColors = {
  primary: "#f15a5b", //wr orange
  secondary: "#def1f6", //wr blue
  warn: "#f15a5b", //red
  info: "#fbde18", //yellow
  grey1: "#262b2c",
  grey2: "#74787b",
  grey3: "#ebebeb",
  grey4: "#383838",
  green: "#3f9f44",
};

export default wrColors;
