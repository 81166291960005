import React, { useState } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import LoginLayout from "./loginLayout";
import TextField from "@mui/material/TextField";
import produce from "immer";
import { useSnackbar } from "notistack";
import { withFirebase } from "components/wr-firebase";
import { withRouter } from "react-router-dom";

interface IState {
  [key: string]: null | string | Error;
}

const INITIAL_STATE: IState = {
  email: "",
  password: "",
  error: null,
};

const LoginContainerBase = (props: any) => {
  const [state, setState] = useState(INITIAL_STATE);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loginWithMicrosoft = () => {
    props.firebase.doSignInWithMicrosoft();
  };

  const onSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { email, password } = state;

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        const nextState = produce(state, () => INITIAL_STATE);
        setState(nextState);
        closeSnackbar();
        enqueueSnackbar("Succesvol ingelogd", {
          variant: "success",
        });
        props.history.push(props.redirect);
      })
      .catch((error: Error) => {
        const nextState = produce(state, (draftSate) => {
          draftSate.error = error;
        });
        setState(nextState);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });

    event.preventDefault();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextState = produce(state, (draftSate) => {
      draftSate[event.target.name] = event.target.value;
    });
    setState(nextState);
  };

  const isInvalid = state.password === "" || state.email === "";
  return (
    <LoginLayout onMicrosoft={loginWithMicrosoft} onSubmit={onSubmit}>
      <>
        <TextField
          value={state.email}
          onChange={onChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Adres"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          value={state.password}
          onChange={onChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Wachtwoord"
          type="password"
          id="password"
        />
        <Button
          disabled={isInvalid}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            margin: (theme) => theme.spacing(3, 0, 2),
          }}
        >
          Log In
        </Button>

        <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Wachtwoord vergeten?
            </Link>
          </Grid>
        </Grid>
      </>
    </LoginLayout>
  );
};

const LoginContainer = withRouter(withFirebase (LoginContainerBase))

export default LoginContainer;
