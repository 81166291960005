import React from "react";
import { ModalContext } from "./context";
import { BasicModal } from "./basicView";
import { ProjectModal } from "./projectView";
import { LocationModal } from "./locationView";

const MODALS: Record<string, React.FC | any> = {
  basic: BasicModal,
  project: ProjectModal,
  location: LocationModal,
  /* other modals */
};

export const ContextModal = () => {
  const { state } = React.useContext(ModalContext);

  if (state.modalType === "none") {
    return <></>;
  }

  const SpecificModal = MODALS[state.modalType];

  return <SpecificModal {...state.modalProps} />;
};
